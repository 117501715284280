import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>都市構造可視化推進機構<span style={{fontSize:"small"}}></span></h1>
            </header>
            <div className="contentstrong">
                <p>第８回 都市計画ビジュアルコミュニケーター検定は、2024年2月7日（水）に実施されます。
                {/* <br /> */}
                <span style={{color:"red"}}></span></p>
                <ul className="actions">
                    <li><a href="/kentei" className="button next scrolly">詳しくはこちらから</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
