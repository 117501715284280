import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
// import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="都市構造可視化推進機構"
                    meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>都市構造可視化計画</h3>
                                <p>都市計画学会・福岡県・建築研究所との連携</p>
                            </header>
                            <Link to="/kashikakeikaku" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>都市計画ビジュアルコミュニケーター検定</h3>
                                <p>都市構造可視化の活用能力を検定します<span style={{color:"red"}}>　次回検定は、2024年2月7日（水）</span>に実施します。</p>
                            </header>
                            <Link to="/kentei" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(/kensyu.jpg)`}}>
                            <header className="major">
                                <h3>研修支援業務</h3>
                                <p>活用研修、データ変換、専用PCの貸し出し等をパッケージでご提供します<span style={{color:"red"}}></span></p>
                            </header>
                            <Link to="/kensyu" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>活用促進事業</h3>
                                <p>可視化の活用事例を収集し提供しています</p>
                            </header>
                            <Link to="/katsuyou" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>OGC会員活動</h3>
                                <p>Open Geospatial Consortiumとの連携</p>
                            </header>
                            <Link to="/ogc" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>i-都市再生の支援</h3>
                                <p>KML・CityGMLデータ等の融合によるスパイラル支援</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>事業概要・入会案内</h2>
                            </header>
                            <p>都市構造可視化推進機構では、各種サービスをご提供しています。サービスの概要および入会のご案内はこちらから。</p>
                            <ul className="actions">
                                <li><Link to="/gaiyou" className="button next">事業概要</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex